import React from 'react'
import '../components/Footer.css'

function Footer() {
  return (
    <div className='footer-container pt-3' id='contacts'>
    <div className='row '>
        <div className='col-6 col-md-4 '>
           <h4>МЫ РАБОТАЕМ:</h4>
           <h5>понедельник-пятница:</h5>
           <p>с 9.00 до 18.00</p>
           <p>суббота-воскресенье:выходной</p>
           <a href="https://www.facebook.com/Cardentmd-108070781339303" 
        class="facebook" target="_blank" rel="noreferrer" ><i class="fab fa-facebook-square"></i></a>
         <a href="https://www.youtube.com/channel/UCDROnqG8P5vnLpwaDl9QSjw" 
        class="facebook" target="_blank" rel="noreferrer" ><i class="fab fa-youtube-square"></i></a>
        </div>
        
        <div className='col-6 col-md-4 '><h2>+373 787 78 444</h2> <h2>+373 060 737 824</h2> 
        <h4>Chișinău, Moldova
</h4>
        <h4>Mesterul Manole 18/2</h4> 
</div>
        <div className='col-6 col-md-4'> 
        <img className="img_logo " src="../images/logo-circle.png" path="/about" alt=""/>
        </div>
        
    </div>
    <a href="https://www.nextek.se/" target="_blank" rel="noreferrer" ><div className='nextek'>© 2022  Digital Magic by NexTek</div></a> 
    </div>
  )
}

export default Footer