import React, { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

const ShoppingCart = ({ cart, setCart }) => {
  const [totalPrice, setTotalPrice] = useState(0);

  const incrementQuantity = (productId) => {
    const updatedCart = cart.map((item) => {
      if (item.product.id === productId) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
    setCart(updatedCart);
  };

  const decrementQuantity = (productId) => {
    const updatedCart = cart
      .map((item) => {
        if (item.product.id === productId) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      })
      .filter((item) => item.quantity > 0);
    setCart(updatedCart);
  };

  const handleDelete = (productId) => {
    const updatedCart = cart.filter((item) => item.product.id !== productId);
    setCart(updatedCart);
  };

  const getTotalPrice = () => {
    let price = 0;
    for (const item of cart) {
      price += item.product.price * item.quantity;
    }
    setTotalPrice(price);
  };

  return (
    <Container>
      <Row>
        <h1>SHOPPING CART</h1>
        {/* {cart.length === 0 ? (
          <Col>
            <h3>Your cart is empty.</h3>
          </Col>
        ) : (
          <>
            {cart.map((item) => (
              <Col key={item.product.id} md={4} className="my-3">
                <Card>
                  <Card.Img variant="top" src={item.product.image} />
                  <Card.Body>
                    <Card.Title>{item.product.name}</Card.Title>
                    <Card.Ty: {item.quantity}</p>
                    <Button
                      variant="success"
                      onClick={() => incrementQuantity(item.product.id)}
                    >
                      +
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => decrementQuantity(item.product.id)}
                    >
                      -
                    </Button>
                    <p>Price: {item.product.price * item.quantity} USD</p>
                    <Button
                      variant="warning"
                      onClick={() => handleDelete(item.product.id)}
                    >
                      Delete
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))} 
            <Col>
              <h3>Total Price: {totalPrice} USD</h3>
              <Button variant="success" onClick={getTotalPrice}>
                Get Total Price
              </Button>
            </Col>
          </>
        )}*/}
      </Row>
    </Container>
  );
};

export default ShoppingCart;
