import React, { Component } from "react";
import Slider from "react-slick";
import "./ImageSlide.css";
import 'bootstrap/dist/css/bootstrap.min.css';

export default class Fade extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true, 
      speed: 3000,
      autoplaySpeed: 2000,
      cssEase: "linear"
    };
    return (
      <div className="slider-container " id='gallery'>
 <h3 className='mb-5' >Возможности технологии PDR на примерах наших работ
</h3>
      <div className="image-slider ">
         
        <Slider {...settings}>
          <div>
          <img className=" img-y" src="../images/car1.jpg"  path="/about" alt=""/>
          </div>
          <div>
          <img className=" img-y" src="../images/car2.jpg"  path="/about" alt=""/>
          </div>
          <div>
          <img className=" img-y" src="../images/car3.jpg"  path="/about" alt=""/>
          </div>
          <div>
          <img className=" img-y" src="../images/car4.jpg"  path="/about" alt=""/>
          </div>
          <div>
          <img className=" img-y" src="../images/car5.jpg"  path="/about" alt=""/>
          </div>
          <div>
          <img className=" img-y" src="../images/carmax.png"  path="/about" alt=""/>
          </div>
        
        </Slider>
      </div>
      </div>
    );
  }
}