import React from 'react'
import BlogQwestions from '../components/BlogQwestions'

function Blog() {
  return (
    <div className='blog' id='blog'>
  
    <BlogQwestions/>
    </div>
  )
}

export default Blog