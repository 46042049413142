import React from 'react'
import '../components/ServicesIcons.css'
import Fade from 'react-reveal/Fade';


function ServicesIcons() {
  return (
    <div className='servicesIcons-container ' id='services'>

    <div className='row'>
    <h3 className='mb-5' >Наши услуги</h3>
    <Fade left>
     <div className='col-8 col-md-3 icons icon-1'>
     <h4 className='text-center'>Удаление парковочных вмятин</h4>
     <p>Вмятины, которые образовались на кузове автомобиля после неудачной парковки.</p>
     </div>
     </Fade>  
     <Fade top>
     <div className='col-8 col-md-3 icons icon-2'>
     <h4 className='text-center'>Удаление вмятин после града
</h4>
     <p>Ливень вперемешку с летящими с неба ледышками способен без проблем пробить не только стекла, 
     но и покрыть весь кузов автомобиля десятками мелких и крупных вмятин.</p>
     </div>
     </Fade>  
     <Fade right>
     <div className='col-8 col-md-3 icons icon-3'>
     <h4 className='text-center'>Рихтовка под покраску (без нанесения шпаклевки)</h4>
     <p>Высокоточная рихтовка методом PDR (Paintless dent repair). Выведение плоскости панелей
      автомобиля в идеальный уровень, что позволяет минимизировать процесс шпаклевания или вовсе исключает его.</p>
     </div>
   </Fade>
   <Fade right>
     <div className='col-8 col-md-3 icons icon-4'>
     <h4 className='text-center'>Обучающий курс по удалению вмятин без покраски</h4>
     <p>Обучающий курс позволяющий за 10 дней освоить азы удаления вмятин без покраски с автомобиля.

</p>
     </div>
   </Fade>
    </div>
    </div>
  )
}

export default ServicesIcons