import React from 'react'
import './FirstCar.css' 

function FirstCar() {
  return (
    <div className='first-component-container'>
   <div className='title-fc row' > <h1 className='h3-color'>Cardent - это </h1> 
   <div className='col-6'>
   <h3 className='h3-color'>Доступные цены</h3>
   <p>Никогда не огорчим Вас завышенными ценами. К нам гораздо выгоднее обратиться, чем к авто маляру.</p></div>
   <div className='col-6'>
   <h3 className='h3-color'>Вмятины любой сложности</h3>
   <p>Вы можете присутствовать при процессе ремонта авто и узнать много нового о Вашем стальном друге.</p></div>
   <div className='col-6'>
   <h3 className='h3-color'>Минимальные сроки</h3> 
   <p>Ценим Ваше время и дорожим своим.
    Поэтому прилагаем все усилия, чтобы выполнить все работы в максимально короткие сроки и без потери в качестве.</p>
</div>
   <div className='col-6'>
   <h3 className='h3-color'>Надежно</h3>
   <p>Качество наших работ является примером для многих конкурентов.
    Мы предоставляем услуги по ремонту вмятин в Кишинёве уже более 3 лет и 78% клиентов приходит к нам по рекомендациям.</p></div>
  

   </div> 

    </div>
  )
}

export default FirstCar