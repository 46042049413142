import React from 'react'
import EmptyHome from '../components/EmptyHome'
 import FirstCar from '../components/FirstCar'
// import Galery from '../components/Galery'
import Grid from '../components/Grid'
import Hero from '../components/Hero'
import Fade from '../components/ImageSlide'
import Service2 from '../components/Service2'
import ServicesIcons from '../components/ServicesIcons'
import Welcome from '../components/Welcome'

function Home() {
  return (
    <>
   
    <Hero/>
    <ServicesIcons/>
    <Welcome/>
    <EmptyHome/>
    <Grid/>
    <Service2/>
     <FirstCar/>
     {/* <Galery/> */}
     <Fade/>
    </>
  )
}

export default Home