import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import "./App.css";
import Footer from "./components/Footer";
import Blog from "./pages/Blog";
import Welcome from "./components/Welcome";
import ServicesIcons from "./components/ServicesIcons";
import ImageSlide from "./components/ImageSlide";
import Contacts from "./pages/Contacts";
import Products from "./pages/Products";
import ShoppingCart from "./pages/ShoppingCart";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about" element={<Welcome />} />
          <Route path="/services" element={<ServicesIcons />} />
          <Route path="/gallery" element={<ImageSlide />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/products" element={<Products />} />
          <Route path="/shoppingCart" element={<ShoppingCart />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
