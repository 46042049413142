import React from 'react'
 import Fade from 'react-reveal/Fade';

import '../components/Hero.css'

function Hero() {
  return (
    <div className='container-hero' id='home'>
    <Fade left>
    <div className='hero-text-box'>
       <h1 className='hero-title'>
       БЕСПОКРАСОЧНОЕ УДАЛЕНИЕ ВМЯТИН В КИШИНЁВЕ ПО НЕМЕЦКОЙ ТЕХНОЛОГИИ</h1> 

<p className='hero-text'>
Удаление вмятин без покраски (технология Paintless Dent Repair – PDR) в Кишинёвe – одна из востребованных услуг:
 поверхность автомобиля может быть деформирована не только при ДТП или неаккуратной эксплуатации, но и в самую обычную
  непогоду. Необходимость убрать неровности на кузове возникает довольно часто.</p>
<p>К радости автомобилистов, существуют технологии, с помощью которых можно убрать вмятину на машине без 
традиционной рихтовки и покраски, устранить повреждения в труднодоступных местах. Предпродажная подготовка
 авто включает ремонт всех вмятин кузовной части без повреждения лакокрасочного покрытия. Этот процесс позволит
  вернуть автомобилю привлекательный внешний вид.</p>
 <p> Мастера автостудии «CarDent» выполняют ремонт вмятин на кузовных элементах без покраски, а это значит,
   что можно избавиться от дефекта, не вызывая лишних вопросов при дальнейшей продаже авто и не рискуя ошибиться с цветом. </p>




    </div>
</Fade>
<Fade right>
    <div className='hero-text-box2'>
       <h1 className='hero-title'>ПОЧЕМУ ИМЕННО МЫ?</h1> 
<p className='hero-text'>CarDent - предприятие, которое бережёт ваши инвестиции: регулярный уход за авто и 
обработка поверхностей качественными защитными составами, сохраняет внешний вид и гарантирует что с 
течением времени износ обработанных поверхностей будет минимальным. </p>

<p className='hero-text'>Благодаря регулярному уходу, ваше авто сохранит свою стоимость, привлекательный 
внешний вид и шарм и вы будете влюбляться в него заново каждый раз, забирая авто после услуг детейлинга в нашей студии.</p>
    </div>
</Fade>
    
    
    </div>
  )
}

export default Hero