import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { AiOutlineShoppingCart } from "react-icons/ai";

import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const handleClick = () => setClick(!click);

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);
  return (
    <>
      <nav className={navbar ? "navbar active" : "navbar"}>
        <div className="nav-container fixed-top">
          <NavLink to="/" className="nav-logo align-middle">
            <div>
              <img
                className="img_logo_nav "
                src="../images/logo-circle.png"
                path="/about"
                alt=""
              />
            </div>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item ">
              <a
                href="/"
                // activeClassName="active "
                className="nav-links "
                onClick={handleClick}
              >
                Главная
              </a>
            </li>
            <li className="nav-item ">
              <a
                href="/#about"
                // activeClassName="active "
                className="nav-links"
                onClick={handleClick}
              >
                О нас
              </a>
            </li>
            <li className="nav-item ">
              <a
                href="/#services"
                // activeClassName="active "
                className="nav-links"
                onClick={handleClick}
              >
                Услуги
              </a>
            </li>
            {/* <li className="nav-item ">
              <a
                href="/products"
                // activeClassName="active "
                className="nav-links"
                onClick={handleClick}
              >
                Products
              </a>
            </li> */}
            <li className="nav-item ">
              <a
                href="/#gallery"
                // activeClassName="active "
                className="nav-links"
                onClick={handleClick}
              >
                Галерея
              </a>
            </li>
            <li className="nav-item ">
              <NavLink
                to="/blog"
                activeClassName="active "
                className="nav-links"
                onClick={handleClick}
              >
                Блог
              </NavLink>
            </li>
            {/* <li className="nav-item ">
              <a
                href="/shoppingCart"
                // activeClassName="active "
                className="nav-links"
                onClick={handleClick}
              >
                <AiOutlineShoppingCart />
              </a>
            </li> */}
            <li className="nav-item ">
              <NavLink
                to="/contacts"
                activeClassName="active "
                className="nav-links"
                onClick={handleClick}
              >
                Контакты
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
