import React from 'react'
import '../components/Grid.css'
// import Fade from 'react-reveal/Fade';

function Grid() {
  return (
    <div className='grid-container '>
     <div className="testimonial-grid">

     {/* FIRST */}
     {/* <Fade delay='100'> */}
    <div className="zoom testimonial grid-col-span-2 flow bg-primary-400 quote text-neutral-100">
    </div>

    {/* SECOND */}
    {/* <Fade delay='200'> */}
    <div className="zoom testimonial flow bg-secondary-400 text-neutral-100 second">
    </div>

    {/* THIRD */}
    {/* <Fade delay='300'> */}
    <div className="zoom testimonial flow bg-neutral-100 text-secondary-400 third ">
    </div>

{/* 
    FOUR */}
    {/* <Fade delay='400'> */}
    <div className="zoom testimonial grid-col-span-2 flow bg-secondary-500 text-neutral-100 four">
 </div>

{/* FIVE */}
{/* <Fade delay='500'> */}
    <div className="zoom testimonial flow bg-neutral-100 text-secondary-400 five">
  </div>

      {/* new line */}
      {/* <Fade delay='100'> */}
    <div className="zoom six grid-col-span-2 flow bg-primary-400 quote text-neutral-100 ">
    </div>

    {/* SECOND */}
    {/* <Fade delay='200'> */}
    <div className="zoom testimonial flow bg-secondary-400 text-neutral-100 seven">
  </div>

    {/* THIRD */}
    {/* <Fade delay='300'> */}
    <div className="zoom testimonial flow bg-neutral-100 text-secondary-400 eight ">
   </div>
   
{/* 
    FOUR */}
    {/* <Fade delay='400'> */}
    <div className="zoom testimonial grid-col-span-2 flow bg-secondary-500 text-neutral-100 nine">
 </div>

{/* FIVE */}

  </div>

    </div>
  )
}

export default Grid