import React from 'react'
import Fade from 'react-reveal/Fade';
import '../components/Service2.css'

function Service2() {
  return (
    <div className='service2-container'>
    <h1 className='pb-5'>С появлением PDR-технологии стало возможным:</h1>
        <div className='row'>
    <Fade top>
     <div className='col-6 col-md-3 icons-2 '>
     <h4 className='text-center'>Не разбирать машину</h4>
     <p>перед проведением работ. В отдельных случаях может понадобиться снятие обшивки. 
     В результате удается сохранить заводскую сборку;</p>
     </div>
     </Fade>  
     
     <Fade bottom>
     <div className='col-6 col-md-3 icons-2 '>
     <h4 className='text-center'>Сократить время ремонта до нескольких часов,</h4>
     <p>в зависимости от объема работ; прилагаем все усилия, чтобы выполнить все работы в максимально короткие сроки и без потери в качестве.</p>
     </div>
     </Fade>  
     <Fade top>
     <div className='col-6 col-md-3 icons-2 '>
     <h4 className='text-center'>Обеспечить сохранность лакокрасочного покрытия.</h4>
     <p>Если на нем все же остались царапины или сколы, то их заполняют специальным составом и краской,
      подобранной с помощью компьютера;</p>
     </div>
     </Fade>  
     <Fade bottom>
     <div className='col-6 col-md-3 icons-2 '>
     <h4 className='text-center'>Значительно снизить финансовые затраты.</h4>
     <p>На удаление вмятин без покраски цена в Кишинёве будет намного ниже, 
     чем стоимость ремонтных работ, проводимых по традиционным технологиям.</p>
     </div>
   </Fade>
    </div>
    </div>
  )
}

export default Service2