import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Product from "./Product";

const product = [
  {
    id: 1,
    name: "Product 1",
    image: "https://www.radne.se/pub_images/original/64022.jpg",
    description: "This is the description for Product 1",
    price: 9.99,
  },
  {
    id: 2,
    name: "Product 2",
    image:
      "https://www.kjell.com/globalassets/productimages/836249_88294.tif?ref=0DA26B2670&format=jpg&w=960&h=960&mode=pad",
    description: "This is the description for Product 2",
    price: 19.99,
  },
];

const Products = () => {
  const [cart, setCart] = useState([]);

  const addToCart = (product) => {
    setCart([...cart, product]);
  };

  return (
    <Container>
      <Row>
        {product.map((product) => (
          <Col key={product.id} md={4} className="my-3">
            <Product product={product} addToCart={addToCart} />
          </Col>
        ))}
      </Row>
      <Row>
        <Col>
          <p>Items in cart: {cart.length}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Products;
