import React from 'react'
import './BlogQwestions.css'
import backgroundVideo from '../video/max.slow.mp4';
import blog1Video from '../video/blog1.mp4'
import blog2Video from '../video/blog2.mp4'

function BlogQwestions() {
  return (
    <div className='blogQwestions-container '>
        <h2 className='b-title-main'>Популярные вопросы при заказе услуги удаление вмятин без покраски</h2>
        <ul>
            <li>
                <h4 className='title-blog'>Каковы сроки выполнения ремонта вмятин без покраски?</h4>
                <p>Все зависит от глубины и размера поврежденного участка. Удаление мелких вмятин занимает 10 минут, а крупных — не более суток.</p>
            </li>
            <li>
                <h4 className='title-blog'>Есть ли риск, что после проведения ремонта методом PDR вмятина возникнет снова?</h4>
                <p>Если работа была выполнена профессионалом и в полном соответствии с технологией, риск возникновения вмятины отсутствует.</p>
            </li>
            <li>
                <h4 className='title-blog'>Насколько эффективно самостоятельное удаление вмятины?</h4>
                <p>Эта работа требует не только специализированного инструмента: для качественного устранения вмятины нужны практические знания и опыт. 
                Результатом неквалифицированного ремонта обычно становится возникновение новых сколов на ЛКП.</p>
            </li>
            <li>
                <h4 className='title-blog'>Не лопнет ли краска при ремонте методом PDR?</h4>
                <p>Мы обязательно предупреждаем клиентов, если имеется такая опасность. 
                Однако у наших профессионалов подобные проблемы возникают очень редко.</p>
            </li>
            <li>
                <h4 className='title-blog'>Беспокрасочный ремонт подходит для удаления любых вмятин?</h4>
                <p>Избавиться от дефектов можно в 90% случаев: все зависит от размеров вмятины и места, где она расположена. 
                Наши сотрудники способны устранить методом PDR дефекты любой глубины и размеров на самых различных участках.</p>
            </li>
        </ul>
        <div>
        <video controls className='video-blog'>
        <source
          src={backgroundVideo} 
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> 
      <div>
      <h3 className='b-title-main mt-5'>Удаление вмятин без покраски на кузове автомобиля в Кишинёве</h3>
        <ul>
            <li>
                <h4 className='title-blog'>Удаление вмятин без покраски -</h4>
                <p>это современная и прогрессивная технология, которая  предполагает использование специального  инструмента. На всю процедуру может потребоваться как 10 минут, так и определенное количество часов. 
                Удаление вмятин вакуумом имеет множество преимуществ перед хорошо известным автолюбителям кузовным ремонтом.  Эта работа в нашем сервисе осуществляется с помощью самых лучших в настоящее время профессиональных инструментов, которые производят компания Nussle Spezialwerkzeuge, Ultra Dent Tools, Blehm, AV-TOOL, BETAG INNOVATION.
                 Специалисты нашего цеха по ремонту вмятин без покраски на кузове прошли обучение по использованию данной технологии на практике.</p>
            </li>
            <h3 className='b-title-main'>Преимущества беспокрасочного удаления вмятин в Кишинёве</h3>
            <li>
                <h4 className='title-blog'>невысокая стоимость,</h4>
                <p>так как на проведение работ требуется значительно меньше времени. Также можно сэкономить на 
                приобретении шпаклевочных материалов, краски и других расходных материалов, которые необходимы для обычного ремонта;</p>
            </li>
            <li>
                <h4 className='title-blog'>быстрое выполнение ремонта,</h4>
                <p>так как большинство вмятин по этому методу можно выправить в течение 30 минут. За время оформления заказа - наряда все работы по удалению вмятин на автомобиле будут полностью закончены.
                 Даже при снятии обшивки на ремонт уйдет только один день. На ремонт вмятин методом окрашивания требуется примерно 3 дня;</p>
            </li>
            <li>
                <h4 className='title-blog'>лучшие результаты ремонта. </h4>
                <p>После окончания процедуры останется только заводское покрытие, не отличимое по цвету от поверхности кузова. 
                Таким образом, от вмятины не останется даже следа;
</p>
            </li>
            <li>
                <h4 className='title-blog'>данная технология не наносит вреда</h4>
                <p>здоровью людей и окружающей среде, так как не применяются токсичные краски и 
                другие материалы, необходимые для стандартного кузовного ремонта. После такого ремонта не бывает никаких отходов.
</p>
            </li>
        </ul>
      </div>
      <div>
        <video controls className='video1-blog'>
        <source
          src={blog1Video} 
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> 
      <video  controls className='video1-blog'>
        <source
          src={blog2Video} 
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> 
      </div>
        </div>
    </div>
  )
}

export default BlogQwestions