import React from 'react'
import '../components/Welcome.css'

function Welcome() {
  return (
    <div className='welcome-container mx-auto' id='about'>
     <div className='row '>
     <h3 className='mb-5' >Добро пожаловать в CarDent</h3>
         <div className='col-10 col-md-6 col-lg-6 mx-auto welcome-firstCol'>
             
             <p className='text-left'>Мы рады приветствовать Вас на нашем сайте,
              посвященном беспокрасочному удалению вмятин в Кишинёве. 
             Компания «CarDent» специализируется на беспокрасочном ремонте уже 5 лет.
              За это время мы убрали повреждения, более чем на 3 000 автомобилей, и наработали практический опыт 
              и навыки по данной технологии. Мастера проходили подготовку в "Av masters" - это ведущий
               сертифицированный учебный центр по удалению вмятин без покраски по немецкой технологии DOL. Работаем
                со специальным инструментом Nussle Spezialwerkzeuge (Германия), Ultra Dent Tools и Blehm (США),
                 AV-TOOL (Россия), BETAG INNOVATION (Швейцария). Мы занимаемся только ремонтом вмятин и оказываем 
                 свои услуги на высоком профессиональном уровне!</p>
            
         </div>
         <div className='col-10 col-md-6 col-lg-6 mx-auto welcome-secondCol'>
             <img className='welcome-img' src='../images/14.jpg' alt=''  />
         </div>
     </div>
    </div>
  )
}

export default Welcome;