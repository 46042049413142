import React from 'react'
import '../components/Footer.css'

function Contacts() {
  return (
    <div className='contacts-container'>
    <h3 className='slogan mt-3 mb-3'>Удаление вмятин требует особого навыка, доверяйте свои машины только профессионалам.</h3>
    
     <div className='row'>
        <div className='col-12 col-md-6'> <h4>Chișinău, </h4>
        <h4>Moldova</h4>
         <h4>Mesterul Manole 18/2</h4>
         <h4 className='phone-color'>+373 787 78 444</h4>
         <h4 className='phone-color'>+373 060 737 824</h4>
         </div>
        <div className='col-12 col-md-6'> <iframe title="address" src="https://maps.google.com/maps?q=Mesterul%20Manole%2018/2&t=&z=13&ie=UTF8&iwloc=&output=embed" 
        frameborder="0" scrolling="no" marginheight="0" marginwidth="0"  width="auto" height="auto" style={{border:0}} allowFullScreen=""
         loading="lazy" referrerPolicy="no-referrer-when-downgrade">
         </iframe></div>
         
     </div>
   
     
    </div>
  )
}

export default Contacts