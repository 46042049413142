import React from 'react'
import '../components/EmptyHome.css'
// import backgroundVideo from '../video/video.mp4'

function EmptyHome() {
  return (
    <div>
      {/* <video loop autoPlay>
        <source
          src={backgroundVideo} 
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> */}
    <div className='emptyHome-container '>
   

<div className='row bothText'>
    <div className='col-12 first-emptyText text-center'>
    {/* <img src='../images/6.jpg' className='w-50' alt='' /> */}
        <h2 >БЕСПОКРАСОЧНОЕ УДАЛЕНИЕ ВМЯТИН В КИШИНЁВЕ </h2>
        <h2>ПО НЕМЕЦКОЙ ТЕХНОЛОГИИ </h2>
    </div>
    
    {/* <div className='col-6 second-emptyText'>
  
    </div> */}
</div>
    </div>
    </div>
  )
}

export default EmptyHome