import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";

const Product = ({ product, addToCart }) => {
  const [clicked, setClicked] = useState(false);

  const handleAddToCart = () => {
    addToCart(product);
    setClicked(true);
  };

  return (
    <Card>
      <Card.Img variant="top" src={product.image} />
      <Card.Body>
        <Card.Title>{product.name}</Card.Title>
        <Card.Text>{product.description}</Card.Text>
        <Button
          variant={clicked ? "danger" : "primary"}
          onClick={handleAddToCart}
        >
          {clicked ? "Added to Cart" : "Add to Cart"}
        </Button>
      </Card.Body>
    </Card>
  );
};
export default Product;
